<template>
  <div class="pageview">
    <!-- <van-sticky>
      <van-row>
        <van-col span="5"
          style="font-size: 16px;"
          class="call">
          <div class="sousuo">洛阳&nbsp;&nbsp;
            <van-icon name="arrow-down"
              @click="showPopup" />
          </div>
          <van-popup v-model="show"
            round
            position="bottom">
            <van-cascader v-model="activeNames"
              title="请选择所在地区"
              :options="options"
              @close="show = false"
              @finish="onFinish" />
          </van-popup>
        </van-col>
        <van-col span="19">
          <van-search style="padding-left:0;"
            v-model="listfrom.name"
            shape="round"
            @blur="search"
            placeholder="请输入搜索关键词" />
        </van-col>
      </van-row>
    </van-sticky> -->

    <div style="width:95%;margin:auto;padding-top:7px;">
      <van-list :loading="loading"
        :finished="finished"
        :offset="130"
        finished-text="没有更多了"
        @load="onLoad">
        <div class="waterfall">
          <div class="list"
            v-for="item in list"
            :key="item.LSId"
            @click="gotoUrlZy('/travel/jingqu/jqJieShao/attractions/'+ item.LSId)">
            <img v-if="item.Imgs == ''"
              src="https://xczxapi.zhsq.cloud//upload/images/2022/5/5a80ec81-50a.png" />
            <img v-else
              :src="url +item.Imgs.split(',')[0]" />
            <!-- <img :src="item.ThematicUrl"
            alt=""> -->
            <div style="width:90%;margin:auto;">
              <div class="address">{{item.Name}}</div>
              <div class="count van-multi-ellipsis--l3">{{item.Describe}}</div>
              <br>
              <!-- <div class="money">¥{{item.Cost}} <span style="color:font-size:16px;">起</span> </div> -->
            </div>

          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { WeGetLandScapePage } from "@/api/jingqu";
import config from "@/config"
export default {
  data () {
    return {
      url: config.hostUrl,
      show: false,
      options: [],
      activeNames: {},
      TitleList: [],
      NoticeList: [
        { RIId: '1', Title: '洛阳水席>牡丹燕菜', address: '洛阳·栾川县', ThematicUrl: 'https://xczxapi.zhsq.cloud//upload/images/2022/5/fda33f6c-26d.png', count: '洛阳水席，是河南洛阳一带特色传统名宴，属于豫菜系。洛阳水席', money: '20' },
        { RIId: '2', Title: '洛阳不翻汤', address: '洛阳·嵩县', ThematicUrl: 'https://xczxapi.zhsq.cloud//upload/images/2022/5/99ebd0d2-b08.png', count: '洛阳水席，是河南洛阳一带特色传统名宴，属于豫菜系。洛阳水席', money: '38' },
        { RIId: '2', Title: '洛阳不翻汤', address: '洛阳市', ThematicUrl: 'https://xczxapi.zhsq.cloud//upload/images/2022/5/99ebd0d2-b08.png', count: '洛阳水席，是河南洛阳一带特色传统名宴，属于豫菜系。洛阳水席', money: '38' },
      ],
      list: [],
      listfrom: {
        type: 1,
        SSId: this.$route.params.Id,
        page: 1,
        limit: 10,
      },
      hidden: true, //没有更多数据了
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
    }
  },
  created () {
    // this.getScenicSpotPageList("1")
    this.getLandScapePage()
  },
  methods: {
    showPopup: function () {
      this.show = true
    },
    onFinish: function (val) {
      this.dataForm.OName = val.selectedOptions[1].OName;
      this.dataForm.OCode = val.selectedOptions[1].OCode;
      console.log(this.dataForm);
      // this.dataForm.OCode = val.value;
      this.show = false
    },
    // tab切换
    onClick (name, title) {
      this.list = []
      this.listfrom.SStypeId = name
      this.listfrom.page = 1
      this.getLandScapePage()
    },
    // 搜索
    search: function () {
      this.listfrom.name = this.listfrom.name
      this.getLandScapePage()
    },
    onLoad () {
      console.log(123);
      this.listfrom.page = this.listfrom.page + 1;
      this.getLandScapePage();
    },
    // 景区推荐
    getLandScapePage () {
      WeGetLandScapePage(this.listfrom).then((res) => {
        // this.jqTjList = res.data.data;
        // 加载状态结束
        let rows = res.data.data; //请求返回当页的列表
        this.loading = false;

        if (res.data.code == 0) {
          if (rows == null || rows.length === 0) {
            // 加载结束
            this.finished = true;
            return;
          }
          // 将新数据与老数据进行合并
          this.list = this.list.concat(rows);

          //如果列表数据条数>=总条数，不再触发滚动加载
          if (this.list.length >= res.data.count) {
            this.finished = true;
          }
        }
      })
    },
    // 主要景点
    gotoUrlZy (path) {
      this.$router.push(path);
    },
  }
}
</script>

<style scoped>
.pageview {
  /* height: 100%; */
  background-color: #f8f8f8;
  min-height: 100%;
}
.sousuo {
  height: 54px;
  background: white;
  line-height: 54px;
  padding-left: 15%;
  margin: auto;
  color: red;
}
.waterfall {
  /* width: 95%; */
  margin: 0 auto;
  column-count: 2;
  column-gap: 0;
  column-width: auto;
}
.waterfall .list {
  margin: 0px 7px 10px 7px;
  break-inside: avoid;
  border-radius: 5px;
  background-color: #ffffff;
}
.list img {
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  /* height: 160px; */
  /* border-radius: 0px; */
}
.address {
  margin: 10px 0;
  color: #92600d;
  font-size: 14px;
}
.count {
  color: #000000;
  font-size: 17px;
}
.money {
  margin: 10px 0;
  padding-bottom: 10px;
  color: #fe5b47;
  font-size: 18px;
}
</style>